import React, { useState, useEffect } from 'react';
import { Button, message } from 'antd';
import { QRCodeCanvas } from 'qrcode.react';
import { Carousel } from 'antd'; // Ant Design Carousel
import profilepic from  '../components/photos/nfcpie.png'
import './ButtonSheet.css';

const ButtonSheetModal = ({ userData }) => {
  const [visibleMenu, setVisibleMenu] = useState(false);
  const [currentMenu, setCurrentMenu] = useState(null);

  const menuItems = [
    ...(userData.ibans && userData.ibans.length > 0 ? [{ type: 'banka', label: 'Banka Bilgileri', icon: 'fas fa-university fa-xl' }] : []),
    ...(userData.events && userData.events.length > 0 ? [{ type: 'galeri', label: 'Galeri', icon: 'fas fa-images fa-xl' }] : []),
    { type: 'paylas', label: 'Paylaş', icon: 'fas fa-share-alt fa-xl' },
    ...(userData.qrCodes && userData.qrCodes.length > 0 ? [{ type: 'QR Kod', label: 'QR Kod', icon: 'fas fa-qrcode fa-xl' }] : []),
    ...(userData.addresses && userData.addresses.find(addr => addr.type === 'INVOICE') ? [{ type: 'fatura', label: 'Fatura Bilgileri', icon: 'fas fa-file-invoice fa-xl' }] : []),

  ];
  

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.bottom-sheet') && !event.target.closest('.sheet-button')) {
        closeMenu();
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const openMenu = (menuType) => {
    if (menuType === 'rehberEkle') {
      downloadVCard();
      return;
    }
    setCurrentMenu(menuType);
    setVisibleMenu(true);

    if (menuType === 'paylas') {
      handleShare();
    }
  };

  const closeMenu = () => {
    setVisibleMenu(false);
    setCurrentMenu(null);
  };

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: `${userData?.user?.firstName} ${userData?.user?.lastName} - Digital Business Card`,
        text: 'İletişim bilgileri',
        url: window.location.href,
      })
      .then(() => {
        console.log('Başarıyla paylaşıldı');
        closeMenu(); // Menü kapatılıyor
      })
      .catch((error) => {
        console.error('Paylaşım başarısız:', error);
        closeMenu(); // Menü kapatılıyor
      });
    } else {
      alert('Paylaşım desteği mevcut değil.');
      closeMenu()
    }
  }; 
  

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      message.success('Kopyalama İşlemi Başarılı');
    } catch (error) {
      console.error('Kopyalama hatası:', error);
      message.error('Kopyalama Başarısız');
    }
  };
  

  const downloadVCard = () => {
    const vCardContent = `
BEGIN:VCARD
VERSION:3.0
N;CHARSET=utf-8:${userData?.user?.lastName};${userData?.user?.firstName};;;
FN;CHARSET=utf-8:${userData?.user?.firstName} ${userData?.user?.lastName}
TITLE;CHARSET=utf-8:${userData?.user?.jobTitle}
ORG;CHARSET=utf-8:${userData?.user?.company}
TEL:${userData.phones.find(s => s.type === "STANDARD").phoneNumber}
EMAIL;INTERNET:${userData?.user?.email}
ADR;WORK;POSTAL;CHARSET=utf-8:${userData.addresses[0]?.fullAddress}
URL:${userData.qrCodes[0]?.link}
END:VCARD
`.trim();

    const blob = new Blob([vCardContent], { type: 'text/vcard' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = 'contact.vcf';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const renderMenuContent = () => {
    switch (currentMenu) {
      case 'QR Kod':
        return renderQRCode();
      case 'galeri':
        return renderGallery();
      case 'banka':
        return renderBankInfo();
        case 'fatura':
        return renderInvoiceAddress();
      default:
        // return renderDefaultMenu();
    }
  };

  const renderInvoiceAddress = () => {
    const invoiceAddress = userData.addresses.find(addr => addr.type === 'INVOICE');
    
    return (
      <div className="bottom-sheet">
        <div className="menu-header">
          <h3>{invoiceAddress.title}</h3>
        </div>
        <div className="invoice-info">
          <p>
            <strong>Vergi Numarası:</strong> {invoiceAddress.description}
            <span 
              className="copy-icon" 
              onClick={() => copyToClipboard(invoiceAddress.description)}
              style={{ cursor: 'pointer', marginLeft: '10px' }}
              title="Kopyala"
            >
              <i className="fas fa-copy"></i>
            </span>
          </p>
          <p>
            <strong>Adres:</strong> {invoiceAddress.fullAddress}
            <span 
              className="copy-icon" 
              onClick={() => copyToClipboard(invoiceAddress.fullAddress)}
              style={{ cursor: 'pointer', marginLeft: '10px' }}
              title="Kopyala"
            >
              <i className="fas fa-copy"></i>
            </span>
          </p>
          <p>
            <strong>Vergi Dairesi:</strong> {invoiceAddress.taxNumber}
            <span 
              className="copy-icon" 
              onClick={() => copyToClipboard(invoiceAddress.taxNumber)}
              style={{ cursor: 'pointer', marginLeft: '10px' }}
              title="Kopyala"
            >
              <i className="fas fa-copy"></i>
            </span>
          </p>
          <p>
            <strong style={{color:"red"}} >Mersis Numarası:</strong> {invoiceAddress.mersisNumber}
            <span 
              className="copy-icon" 
              onClick={() => copyToClipboard(invoiceAddress.mersisNumber)}
              style={{ cursor: 'pointer', marginLeft: '10px' }}
              title="Kopyala"
            >
              <i className="fas fa-copy"></i>
            </span>
          </p>
          <p>
            <strong style={{color:"red"}}>Ticaret Sicil No:</strong> {invoiceAddress.tradeRegistryNumber}
            <span 
              className="copy-icon" 
              onClick={() => copyToClipboard(invoiceAddress.tradeRegistryNumber)}
              style={{ cursor: 'pointer', marginLeft: '10px' }}
              title="Kopyala"
            >
              <i className="fas fa-copy"></i>
            </span>
          </p>
          <p>
            <strong style={{color:"red"}}>KEP Adresi:</strong> {invoiceAddress.kepAddress}
            <span 
              className="copy-icon" 
              onClick={() => copyToClipboard(invoiceAddress.kepAddress)}
              style={{ cursor: 'pointer', marginLeft: '10px' }}
              title="Kopyala"
            >
              <i className="fas fa-copy"></i>
            </span>
          </p>
          <p>
            <strong style={{color:"red"}}>UETS Adresi:</strong> {invoiceAddress.uetsAddress}
            <span 
              className="copy-icon" 
              onClick={() => copyToClipboard(invoiceAddress.uetsAddress)}
              style={{ cursor: 'pointer', marginLeft: '10px' }}
              title="Kopyala"
            >
              <i className="fas fa-copy"></i>
            </span>
          </p>
        </div>
      </div>
    );
  };

  const renderQRCode = () => {
    const qrCodeValue = userData.qrCodes[0]?.link;
    return (
      <div className="bottom-sheet">
        <div className="menu-header">
          <h3>{userData?.qrCodes[0]?.title}</h3>
        </div>
        <div className="qr-code-container" style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <div style={{ position: 'relative', width: 200, height: 200 }}>
            <QRCodeCanvas
              value={qrCodeValue}
              size={200}
              level="H"
              imageSettings={{
                src: profilepic,
                x: 75,    // QR kod içinde resmin x pozisyonu
                y: 75,    // QR kod içinde resmin y pozisyonu
                height: 50, // Resmin yüksekliği
                width: 50,  // Resmin genişliği
                excavate: true,
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderGallery = () => (
    <div className="bottom-sheet-gallery-modal">
      <div className="menu-header">
        <h3>Galeri</h3>
      </div>
      <Carousel>
        {userData.events.map((event, index) => (
          <div 
            key={index} 
            className="carousel-item" 
            style={{ 
              display: "flex", 
              flexDirection: "column", 
              alignItems: "center", 
              justifyContent: "center",
              textAlign: "center" // İçeriklerin ortalanmasını sağlar
            }}
          >
            <img
              src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${event.images[0]}`}
              alt={`Gallery item ${index + 1}`}
              className="gallery-image"
              style={{ 
                objectFit: "contain", 
                maxWidth: "100%", 
                maxHeight: "300px",
                margin: "0 auto" // Resmi tam ortaya hizalar
              }}
            />
            <p>
              <strong>Başlangıç Tarihi:</strong> {new Date(event.startDate).toLocaleDateString()}
            </p>
            <p>
              <strong>Bitiş Tarihi:</strong> {new Date(event.endDate).toLocaleDateString()}
            </p>
          </div>
        ))}
      </Carousel>
    </div>
  );
  
  

  const renderBankInfo = () => (
    <div className="bottom-sheet">
      <div className="menu-header">
        <h3>Banka Bilgileri</h3>
      </div>
      <div className="iban-info">
        {userData.ibans && userData.ibans.length > 0 ? (
          userData.ibans.map((iban) => (
            <BankInfoItem key={iban.id} iban={iban} onCopy={copyToClipboard} />
          ))
        ) : (
          <p>IBAN bilgisi bulunmamaktadır.</p>
        )}
      </div>
    </div>
  );

  // const renderDefaultMenu = () => {
  //   const menuItem = menuItems.find(item => item.type === currentMenu);
  //   return (
  //     <div className="bottom-sheet">
  //       <div className="menu-header">
  //         <h3>{menuItem.label}</h3>
  //       </div>
  //       <p>Burada {menuItem.label.toLowerCase()} bilgileri yer alacak.</p>
  //     </div>
  //   );
  // };

  return (
    <div className="button-sheet">
      {menuItems.map(item => (
        <Button 
          key={item.type} 
          className="sheet-button" 
          onClick={() => openMenu(item.type)}
        >
          <div className="icon-wrapper">
            <i className={item.icon}></i>
          </div>
          <div className="button-label">{item.label}</div>
        </Button>
      ))}

      {visibleMenu && (
        <div className="overlay">
          {renderMenuContent()}
        </div>
      )}
    </div>
  );
};

const BankInfoItem = ({ iban, onCopy }) => (
  <div className="iban-item">
    <p>
      <strong style={{color:"red"}}>Banka Adı:</strong> {iban.title}
      <span 
        className="copy-icon" 
        onClick={() => onCopy(iban.title)} 
        style={{ cursor: 'pointer', marginLeft: '10px' }}
        title="Kopyala"
      >
        <i className="fas fa-copy"></i>
      </span>
    </p>
    <p>
      <strong style={{color:"red"}}>IBAN:</strong> {iban.iban}
      <span 
        className="copy-icon" 
        onClick={() => onCopy(iban.iban)} 
        style={{ cursor: 'pointer', marginLeft: '10px' }}
        title="Kopyala"
      >
        <i className="fas fa-copy"></i>
      </span>
    </p>
    <p>
      <strong>Açıklama:</strong> {iban.description}
      <span 
        className="copy-icon" 
        onClick={() => onCopy(iban.description)} 
        style={{ cursor: 'pointer', marginLeft: '10px' }}
        title="Kopyala"
      >
        <i className="fas fa-copy"></i>
      </span>
    </p>
  </div>
);

export default ButtonSheetModal;
