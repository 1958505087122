import React from 'react';
import { Drawer, Button, message } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { QRCodeCanvas } from 'qrcode.react';
import './BottomSheetComponent.css'; // İkonlar için FontAwesome dahil ediliyorsa bu dosyayı ekleyin

const BottomSheetComponent = ({ open, modalContent, handleCancel }) => {
  const copyToClipboard = () => {
    message.success('Kopyalama İşlemi Başarılı');
  };

  return (
    <Drawer
      title={
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
          <span style={{ textAlign: 'center' }}>{modalContent.label}</span>
        </div>
      }
      placement="bottom"
      onClose={handleCancel}
      open={open}
      className="custom-drawer" // Sınıf adını güncelledim
      height="auto" // Yüksekliği otomatik ayarlayın
      style={{ borderRadius: '10px 10px 0 0' }} // Köşeleri yuvarlatın
    >
      <div
        className="modal-content"
        style={{ maxHeight: '200px', overflowY: 'auto' }} // Maksimum 200px yükseklik ve kaydırma
      >
        {modalContent.type === 'bank' ? (
          <>
            <p className="modal-text"> {/* Sınıf adını güncelledim */}
              <strong>Banka Adı:</strong> {modalContent.content.bankName}
              <CopyToClipboard text={modalContent.content.bankName} onCopy={copyToClipboard}>
                <span className="copy-icon">
                  <i className="fas fa-copy"></i>
                </span>
              </CopyToClipboard>
            </p>
            <p className="modal-text"> {/* Sınıf adını güncelledim */}
              <strong>Hesap Numarası:</strong> {modalContent.content.accountNumber}
              <CopyToClipboard text={modalContent.content.accountNumber} onCopy={copyToClipboard}>
                <span className="copy-icon">
                  <i className="fas fa-copy"></i>
                </span>
              </CopyToClipboard>
            </p>
          </>
        ):modalContent.type === 'crypto' ? (
          <>
            <p className="modal-text"> {/* Sınıf adını güncelledim */}
              <strong>Network:</strong> {modalContent.content.title}
              <CopyToClipboard text={modalContent.content.title} onCopy={copyToClipboard}>
                <span className="copy-icon">
                  <i className="fas fa-copy"></i>
                </span>
              </CopyToClipboard>
            </p>
            <p className="modal-text"> {/* Sınıf adını güncelledim */}
              <strong>Cüzdan Adresi:</strong> {modalContent.content.address}
              <CopyToClipboard text={modalContent.content.address} onCopy={copyToClipboard}>
                <span className="copy-icon">
                  <i className="fas fa-copy"></i>
                </span>
              </CopyToClipboard>
            </p>
            <p className="modal-text"> {/* Sınıf adını güncelledim */}
              <strong>Açıklama:</strong> {modalContent.content.description}
              <CopyToClipboard text={modalContent.content.description} onCopy={copyToClipboard}>
                <span className="copy-icon">
                  <i className="fas fa-copy"></i>
                </span>
              </CopyToClipboard>
            </p>
          </>
        ): modalContent.type === 'qr' ? (
          <>
            <QRCodeCanvas value={modalContent.content} size={200} />
            <p className="modal-text"> {/* Sınıf adını güncelledim */}
              {modalContent.content}
              <CopyToClipboard text={modalContent.content} onCopy={copyToClipboard}>
                <span className="copy-icon">
                  <i className="fas fa-copy"></i>
                </span>
              </CopyToClipboard>
            </p>
          </>
        ) : modalContent.type === 'phone' ? (
          <>
            <p className="modal-text"> {/* Sınıf adını güncelledim */}
              <strong>Numara:</strong> {modalContent.content}
              <CopyToClipboard text={modalContent.content} onCopy={copyToClipboard}>
                <span className="copy-icon">
                  <i className="fas fa-copy"></i>
                </span>
              </CopyToClipboard>
            </p>
            <Button onClick={() => window.location.href = `tel:${modalContent.content}`} className="modal-button">Ara</Button>
          </>
        ) : modalContent.type === 'email' ? (
          <>
            <p className="modal-text"> {/* Sınıf adını güncelledim */}
              <strong>Mail Adresi:</strong> {modalContent.content}
              <CopyToClipboard text={modalContent.content} onCopy={copyToClipboard}>
                <span className="copy-icon">
                  <i className="fas fa-copy"></i>
                </span>
              </CopyToClipboard>
            </p>
          </>
        ): modalContent.type === 'address' ? (
          <>
            <p className="modal-text"> {/* Sınıf adını güncelledim */}
              <strong>Adres:</strong> {modalContent.content}
              <CopyToClipboard text={modalContent.content} onCopy={copyToClipboard}>
                <span className="copy-icon">
                  <i className="fas fa-copy"></i>
                </span>
              </CopyToClipboard>
            </p>
          </>
        ) : (
          <>
            <p className="modal-text"> {/* Sınıf adını güncelledim */}
              {modalContent.content}
              <CopyToClipboard text={modalContent.content} onCopy={copyToClipboard}>
                <span className="copy-icon">
                  <i className="fas fa-copy"></i>
                </span>
              </CopyToClipboard>
            </p>
          </>
        )}
      </div>
    </Drawer>
  );
};

export default BottomSheetComponent;
